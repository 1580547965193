import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/routes/main.vue"),
  },
  {
    path: "/documents",
    component: () => import("@/routes/documents.vue"),
  },
  
];


// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
        return ({
            el: to.hash,
            behavior: 'smooth',
        })
    } else if (savedPosition) {
        return (savedPosition);
    } else {
        return {left: 0, top: 0}
    }
},
});

export default router;
